<template>
  <div>

    <steps :passive-steps="[]" :active-step="7" :finished-steps="[1, 2, 3, 4, 5, 6]" />

    <section class="section">
      <div class="container has-text-centered">
        <h1 class="title is-3 is-marginless">{{ $t('Step 7') }}. {{ $t('Checking your settings') }}</h1>
      </div>
    </section>

    <section class="section">
      <div class="container">
        <div class="content">
          <div class="columns is-centered">
            <div class="column is-8">
              <div class="media">
                <div class="media-content">
                  <p
                    :class="{ 'has-text-grey-light': isTextGreyLight(1), 'has-text-danger': isTextDanger(1), 'has-text-primary': isTextPrimary(1), 'is-marginless': isTextMarginless(1) }">
                    {{ OsText }}
                  </p>
                  <p class="help help-mod">{{ OsHelpText }}</p>
                </div>
                <div class="media-right">
                  <img :src="checkOs" width="32">
                </div>
              </div>
              <div class="media">
                <div class="media-content">
                  <p :class="{ 'has-text-grey-light': isTextGreyLight(2), 'has-text-danger': isTextDanger(2), 'has-text-primary': isTextPrimary(2), 'is-marginless': isTextMarginless(2) }"
                    v-html="BrowserText"></p>
                  <p class="help help-mod" v-html="BrowserHelpText"></p>
                </div>
                <div class="media-right">
                  <img :src="checkBrowser" width="32">
                </div>
              </div>

              <div class="media">
                <div class="media-content">
                  <p
                    :class="{ 'has-text-grey-light': isTextGreyLight(4), 'has-text-danger': isTextDanger(4), 'has-text-primary': isTextPrimary(4), 'is-marginless': isTextMarginless(4) }">
                    {{ SoftwareText }}
                  </p>
                  <p class="help help-mod" v-html="SoftwareHelpText"></p>
                </div>
                <div class="media-right">
                  <img :src="checkSoftware" width="32">
                </div>
              </div>

              <div class="media">
                <div class="media-content">
                  <p
                    :class="{ 'has-text-grey-light': isTextGreyLight(3), 'has-text-danger': isTextDanger(3), 'has-text-primary': isTextPrimary(3), 'is-marginless': isTextMarginless(3) }">
                    {{ TokenText }}
                  </p>
                  <TokenHelpPage v-if="tokenPage" param="step" />
                  <p class="help help-mod" v-html="TokenHelpText"></p>
                </div>
                <div class="media-right">
                  <img :src="checkToken" width="32">
                </div>
              </div>


              <div style="height: 2em;"></div>

              <!-- <div class="media">
                <div class="media-content">
                  <label class="checkbox">
                    <input type="checkbox" name="PKI Root Certificates" v-model="isChecked" v-validate="'checkbox'" :class="{'has-error': errors.has('PKI Root Certificates')}">
                    <template v-html="$t('Confirm that')"></template> <a href="/application/distr/InstallCA_rus.exe"
  target="_blank">{{ $t('PKI Root certificates') }}</a> {{ $t('are installed') }}
</label>
<p class="help help-mod"></p>
<p v-if="errors.has('PKI Root Certificates')" class="alert-error ">{{errors.first('PKI Root Certificates')}}</p>
</div>
<div class="media-right">
  <img :src="checkbox" width="32">
</div>
</div> -->

              <div v-if="catchError">
                <div style="height: 2em;"></div>
                <input class="button is-primary is-medium" type="button" v-on:click="TryAgain"
                  v-bind:value="$t('Try again')">
              </div>

              <div v-if="!catchError">
                <div style="height: 2em;"></div>
                <input class="button right is-primary is-large" type="button" v-on:click="GoToStepSevenDotOne"
                  :disabled="!valid" v-bind:value="$t('Next →')">
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import steps from '../steps/Steps'
import { detect } from 'detect-browser'
import TokenHelpPage from '../steps/TokenHelpPage'
export default {
  name: 'OrderStepSeven',
  components: {
    steps,
    TokenHelpPage
  },
  props: [
    'currentForm'
  ],
  data() {
    return {
      checkukazatel: null,
      socketState: null,
      isChecked: true,
      valid: false,
      allChecked: false,
      textGreyLight: [1, 2, 3, 4, 5],
      textDanger: [],
      textPrimary: [],
      textMarginless: [],
      checkOs: require('../assets/icons/check_silver.svg'),
      checkBrowser: require('../assets/icons/check_silver.svg'),
      checkToken: require('../assets/icons/check_silver.svg'),
      checkSoftware: require('../assets/icons/check_silver.svg'),
      checkbox: require('../assets/icons/check_silver.svg'),
      OsText: this.$i18n.t('Checking your OS').toString(),
      OsHelpText: null,
      BrowserText: this.$i18n.t('Checking your browser').toString(),
      BrowserHelpText: null,
      TokenText: this.$i18n.t('Checking your security token').toString(),
      TokenHelpText: null,
      SoftwareText: this.$i18n.t('Checking whether the software necessary for security token operation is installed').toString(),
      SoftwareHelpText: null,
      devices: null,
      rsaSupport: false,
      catchError: false,
      tokenPage: null
    }
  },
  computed: {
    isSocket() {
      return this.$store.state.socket
    },
    isSocketKey() {
      return this.$store.state.socketKey
    },
    lang() {
      return this.$i18n.locale
    },
    browser() {
      return detect()
    },
    software() {
      return this.browser.os.split(' ')[1]
    }
  },
  methods: {
    isTextGreyLight(id) {
      return this.textGreyLight.find(text => text === id)
    },
    isTextDanger(id) {
      return this.textDanger.find(text => text === id)
    },
    isTextPrimary(id) {
      return this.textPrimary.find(text => text === id)
    },
    isTextMarginless(id) {
      return this.textMarginless.find(text => text === id)
    },
    GoToStepSevenDotOne() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$emit('update:currentForm', 'FormStepSevenDotOne')
        }
      })
    },
    PromiseCheckOs() {
      return new Promise((resolve, reject) => {
        this.checkOs = require('../assets/icons/loading.svg')
        this.textGreyLight = [2, 3, 4]
        const vm = this
        setTimeout(() => {
          if (parseInt(vm.software) >= 7) {
            vm.textPrimary = [1]
            vm.OsText = this.$i18n.t('Your OS is successfully defined').toString()
            vm.checkOs = require('../assets/icons/check_green.svg')
            resolve(vm.checkOs)
          } else {
            this.catchError = true
            vm.textDanger = [1]
            vm.textMarginless = [1]
            vm.OsText = this.$i18n.t('Your OS is not supported').toString()
            vm.OsHelpText = this.$i18n.t('Supported OS').toString()
            vm.checkOs = require('../assets/icons/error.svg')
            reject(vm.OsText)
          }
        }, 2000)
      })
    },
    checkBrowserOk() {
      this.textPrimary = [1, 2]
      this.BrowserText = this.$i18n.t('Your browser is successfully defined').toString()
      this.checkBrowser = require('../assets/icons/check_green.svg')
    },
    checkBrowserBad() {
      this.catchError = true
      this.textDanger = [2]
      this.textMarginless = [2]
      this.BrowserText = this.$i18n.t('Your browser is not supported').toString()
      this.BrowserHelpText = this.$i18n.t('Supported browsers').toString()
      this.checkBrowser = require('../assets/icons/error.svg')
    },
    PromiseCheckBrowser() {
      return new Promise((resolve, reject) => {
        this.checkBrowser = require('../assets/icons/loading.svg')
        this.textGreyLight = [3, 4]
        const vm = this
        setTimeout(() => {
          if (vm.browser) {
            console.log(vm.browser.name)
            console.log(vm.browser.version)
            if (vm.browser.name === 'chrome') {
              if (parseInt(vm.browser.version) >= 49) {
                vm.checkBrowserOk()
                resolve(vm.checkBrowser)
              } else {
                vm.checkBrowserBad()
                reject(vm.BrowserText)
              }
            } else if (vm.browser.name === 'firefox') {
              if (parseInt(vm.browser.version) >= 61) {
                vm.checkBrowserOk()
                resolve(vm.checkBrowser)
              } else {
                vm.checkBrowserBad()
                reject(vm.BrowserText)
              }
            } else if (vm.browser.name === 'ie') {
              if (parseInt(vm.browser.version) >= 10) {
                vm.checkBrowserOk()
                resolve(vm.checkBrowser)
              } else {
                vm.checkBrowserBad()
                reject(vm.BrowserText)
              }
            } else if (vm.browser.name === 'edge') {
              vm.checkBrowserOk()
              resolve(vm.checkBrowser)
            } else if (vm.browser.name === 'opera') {
              if (parseInt(vm.browser.version) >= 55) {
                vm.checkBrowserOk()
                resolve(vm.checkBrowser)
              } else {
                vm.checkBrowserBad()
                reject(vm.BrowserText)
              }
            } else {
              vm.checkBrowserBad()
              reject(vm.BrowserText)
            }
          }
        }, 2000)
      })
    },

    PromiseCheckSoftware() {
      return new Promise((resolve, reject) => {
        this.checkSoftware = require('../assets/icons/loading.svg')
        this.textGreyLight = [3]
        const vm = this
        setTimeout(() => {
          for (let i = 0; i < vm.devices.devices.length; i++) {
            if (vm.devices.devices[i].algorithm === 'RSA') {
              vm.rsaSupport = true
            }
          }
          if (!vm.rsaSupport) {
            if (vm.devices.devices[0].reader.indexOf('SafeNet') >= 0) {
              vm.SoftwareHelpText = this.$i18n.t('It is necessary to install “SafeNet Authentication Client” software').toString()
            } else if (vm.devices.devices[0].name.indexOf('JavaToken') >= 0) {
              vm.SoftwareHelpText = this.$i18n.t('It is necessary to install driver package for eToken PRO 72k (Java)').toString()
            } else if (vm.devices.devices[0].name.indexOf('JaCarta') >= 0) {
              vm.SoftwareHelpText = this.$i18n.t('It is necessary to install driver package for JaCarta') +
                ' - <a href="http://aladdin-rd.ru/support/downloads/43987/">' + this.$i18n.t('”JaCarta Unified Client” software').toString() + '</a>.'
            } else if (vm.devices.devices[0].name.indexOf('KzToken') >= 0) {
              vm.SoftwareHelpText = this.$i18n.t('It is necessary to install the appropriate KzToken software').toString()
            }
            vm.catchError = true
            vm.textDanger = [4]
            vm.textMarginless = [4]
            vm.SoftwareText = this.$i18n.t('The security token software is not detected').toString()
            vm.checkSoftware = require('../assets/icons/error.svg')
            reject(vm.SoftwareText)
          } else {
            vm.textPrimary = [1, 2, 4]
            vm.SoftwareText = this.$i18n.t('The security token software is detected').toString()
            vm.checkSoftware = require('../assets/icons/check_green.svg')
            resolve(vm.checkSoftware)
          }
        }, 2000)
      })
    },

    PromiseCheckSocketState() {
      return new Promise((resolve, reject) => {
        this.checkToken = require('../assets/icons/loading.svg')
        this.textGreyLight = [5]
        const vm = this
        setTimeout(() => {
          if (this.isSocket) {
            if (this.isSocketKey) {
              resolve()
            } else {
              vm.catchError = true
              vm.textDanger = [3]
              vm.textMarginless = [3]
              vm.TokenText = this.$i18n.t('Please install the correct version of the ”CryptoSocket” software').toString()
              vm.checkToken = require('../assets/icons/error.svg')
              reject(vm.checkToken)
            }
          } else {
            vm.catchError = true
            vm.textDanger = [3]
            vm.textMarginless = [3]
            vm.TokenText = this.$i18n.t('Please make sure the ”CryptoSocket” software is running').toString()
            vm.TokenHelpText = this.$i18n.t('Please install the software') + '<a href="/application/distr/SetupCryptoSocket_' + this.lang + '.zip?v=1">CryptoSocket</a>' + this.$i18n.t('if it is not installed on your PC')
            vm.checkToken = require('../assets/icons/error.svg')
            reject(vm.checkToken)
          }
        }, 2000)
      })
    },
    PromiseCheckToken() {
      return new Promise((resolve, reject) => {
        this.devices = this.devices.devices
        let error = null
        if (this.devices.length > 2) {
          error = this.$i18n.t('Found more than one security token').toString()
          this.TokenHelpText = this.$i18n.t('Make sure, that only one security token is connected to the PC and try the operation again').toString()
        }
        if (this.devices[0].name === 'KzToken_not_supported' || this.devices[0].reader.indexOf('Rutoken') >= 0) {
          error = this.$i18n.t('The security token is not supported').toString()
        }
        if (error) {
          this.catchError = true
          this.TokenText = error
          this.textDanger = [3]
          this.textMarginless = [3]
          this.checkToken = require('../assets/icons/error.svg')
          reject(this.TokenText)
        } else {
          this.textPrimary = [1, 2, 3, 4]
          this.TokenText = this.$i18n.t('The security token is supported').toString()
          this.checkToken = require('../assets/icons/check_green.svg')
          resolve(this.checkToken)
        }
      })
    },

    PromiseAllChecked() {
      return new Promise(resolve => {
        this.allChecked = true
        if (this.isChecked) {
          this.valid = true
        }
        resolve(this.allChecked)
      })
    },
    async checkAll() {
      try {
        this.checkukazatel = 1;
        this.checkOs = await this.PromiseCheckOs()
        this.checkukazatel = 2;
        this.checkBrowser = await this.PromiseCheckBrowser()
        this.checkukazatel = 3;
        this.devices = await this.cryptoSocket.getDevices({ id: 4 })
        this.checkSoftware = await this.PromiseCheckSoftware()
        this.checkukazatel = 4;
        this.socketState = await this.PromiseCheckSocketState()
        this.checkToken = await this.PromiseCheckToken()
        this.devices = await this.cryptoSocket.getDevices({ id: 4 })
        this.allChecked = await this.PromiseAllChecked()
      } catch (e) {
        this.catchError = true
        if (e.Function === 'getDevices') {
          if (this.checkukazatel == 3) {
            this.textGreyLight = [3];
            this.textDanger = [4]
            this.textMarginless = [4]
            this.SoftwareHelpText = this.$i18n.t('Connect the security token').toString()
            this.checkSoftware = require('../assets/icons/error.svg')
          } else
            if (this.checkukazatel == 4) {
              this.textDanger = [3]
              this.textMarginless = [3]
              this.checkToken = require('../assets/icons/error.svg')
              this.TokenText = this.$i18n.t('Security token not found or not supported').toString()
              this.tokenPage = true
            }
        }
      }
    },
    TryAgain() {
      location.reload()
    }
  },
  watch: {
    allChecked: {
      handler: function (val) {
        if (!val) {
          this.checkAll()
        }
      },
      immediate: true
    },
    isChecked: {
      handler: function (val) {
        if (val) {
          this.checkbox = require('../assets/icons/check_green.svg')
          if (this.allChecked) {
            this.valid = true
          }
        } else {
          this.checkbox = require('../assets/icons/check_silver.svg')
          this.valid = false
        }
      },
      immediate: true
    }
  },
  created() {
    this.$validator.extend('checkbox', {
      getMessage: field => this.$i18n.t('Not confirmed').toString(),
      validate: value => value
    })
  }
}
</script>

<style scoped>
.help-mod {
  width: 830px;
}

.has-error {
  border-color: red;
}

.alert-error {
  color: red;
}
</style>
