<template>
  <div>

    <section class="section">
      <div class="container has-text-centered">
        <h1 class="title is-3 is-marginless">{{ $t('Revocation of DS Certificate') }}</h1>
        <p class="has-text-grey-light">{{ $t('Before revocation, make sure that the data of revocable certificate is correct') }}</p>
      </div>
    </section>

    <section class="section">
      <div class="container">
        <div class="content">
          <div class="columns is-centered">
            <div class="column is-8">
              <article class="message is-primary">
                <div class="message-header"><p>{{ $t('Certificate data') }}</p></div>
                <div class="message-body has-user-border">
                  <table>
                    <tbody>
                    <tr>
                      <td>{{ $t('Order number') }}</td>
                      <td>{{order}}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('User Type') }}</td>
                      <td>{{userType}}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('Certificate Type') }}</td>
                      <td>{{certificate}}</td>
                    </tr>
                    <tr>
                      <td v-if="role === '1'">{{ $t('Company identification number') }}</td>
                      <td v-if="role !== '1'">{{ $t('Employee identification number') }}</td>
                      <td>{{doc}}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('Country') }}</td>
                      <td>{{country}}</td>
                    </tr>
                    <tr v-if="region">
                      <td>{{ $t('Region') }}</td>
                      <td>{{region}}</td>
                    </tr>
                    <tr v-if="city">
                      <td>{{ $t('City') }}</td>
                      <td>{{city}}</td>
                    </tr>
                    <tr v-if="role === '1' && IdData">
                      <td>{{ $t('Customer data') }}</td>
                      <td>{{IdData}}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('Scan-copy of the document') }}</td>
                      <td><a v-on:click="getDoc()">{{ $t('Show...') }}</a></td>
                    </tr>
                    <tr v-if="role === '1' && IdScanId">
                      <td>{{ $t('Scan-copy of the identification document') }}</td>
                      <td><a v-on:click="getDocId()">{{ $t('Show...') }}</a></td>
                    </tr>
                    <tr>
                      <td>{{ $t('Company name') }}</td>
                      <td>{{orgName}}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('Full name') }}</td>
                      <td>{{fio}}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('Email') }}</td>
                      <td>{{email}}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('DN-name') }}</td>
                      <td>{{dn}}</td>
                    </tr>
                    <tr v-if="templateType !== '1'">
                      <td>{{ $t('Currency') }}:</td>
                      <td>
                        <div class="select is-small">
                          <select v-model="selectedCurrency">
                            <option value="KZT">{{ $t('Tenge') }}</option>
                            <option value="RUB">{{ $t('Rubles') }}</option>
                            <option value="USD">{{ $t('Dollars') }}</option>
                            <option value="EUR">{{ $t('Euro') }}</option>
                          </select>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="templateType !== '1'">
                      <td>{{ $t('Bill language') }}:</td>
                      <td>
                        <div class="select is-small">
                          <select v-model="selectedBillLang">
                            <option value="ru">{{ $t('Russian') }}</option>
                            <option value="en">{{ $t('English') }}</option>
                          </select>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="templateType !== '1'">
                      <td>{{ $t('Value') }}</td>
                      <td class="is-size-4"><strong>{{price}}</strong></td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </article>

              <div style="height: 2rem;"></div>
              <input class="button is-primary is-large" type="button" v-on:click="SendRevoke" v-bind:value="$t('Revoke')">
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import fileSaver from 'file-saver'
export default {
  name: 'Revoke',
  data () {
    return {
      userType: null,
      certificate: null,
      templateType: null,
      doc: null,
      country: null,
      countryShortName: null,
      orgName: null,
      fio: null,
      email: null,
      dn: null,
      scanId: null,
      currencies: null,
      selectedBillLang: 'ru',
      role: null,
      oid: null,
      region: null,
      city: null,
      IdData: null,
      IdScanId: null,
      scanNameId: null
    }
  },
  computed: {
    lang () {
      return this.$i18n.locale
    },
    order () {
      return this.$store.state.order ? this.$store.state.order : ''
    },
    selectedCurrency: {
      get () {
        return this.$store.state.currency ? this.$store.state.currency : 'KZT'
      },
      set (newValue) {
        const currency = this.currencies ? this.currencies.find(item => item.designation === newValue) : null

        if (newValue == 'KZT') {
          this.$store.commit('updateCurrency', newValue)
          this.$store.commit('updateCurrencyId', 4)
          this.$store.commit('updatePrice', 17900)
        } else if (newValue == 'USD') {
          this.$store.commit('updateCurrency', newValue)
          this.$store.commit('updateCurrencyId', 5)
          this.$store.commit('updatePrice', 35)
        } else if (newValue == 'RUB') {
          this.$store.commit('updateCurrency', newValue)
          this.$store.commit('updateCurrencyId', 6)
          this.$store.commit('updatePrice', 3600)
        } else {
          this.$store.commit('updateCurrency', newValue)
          this.$store.commit('updateCurrencyId', 7)
          this.$store.commit('updatePrice', 33)
        }
      }
    },
    price () {
      return this.$store.state.price + ' ' + this.$store.state.currency
    },
    paramsForSubmit () {
      return {
        order: this.order,
        currency: this.$store.state.currencyId,
        fio: this.fio,
        email: this.email,
        price: this.templateType === '1' ? '0' : this.$store.state.price,
        role: this.role,
        oid: this.oid,
        lang: this.$i18n.locale
      }
    }
  },
  watch: {
    currencies: {
      handler: function (val) {
        if (!val) {
          this.$http.get('/query/currencies/').then(response => {
            this.currencies = response.data
          })
        }
      },
      immediate: true
    },
    data: {
      handler: function (val) {
        if (!val) {
          this.$http.get('/query/get-order-info/', { params: { order: this.order, type: 'revoke' } }).then((response) => {
            console.log(response.data)
            if (response.data) {
              val = response.data
              this.userType = this.lang === 'kk' ? val.user_type_kk : (this.lang === 'ru' ? val.user_type : val.user_type_en)
              this.certificate = this.lang === 'ru' ? val.description : val.description_en
              this.templateType = val.certtype
              this.doc = val.docnum
              let reCountry = /\s*_\s*/
              let resCountry = val.country.split(reCountry)
              this.country = resCountry[2]
              this.countryShortName = resCountry[1]
              this.region = val.region
              this.city = val.city
              this.orgName = val.legal ? (val.legal + ' ' + val.name) : val.name
              this.fio = val.fio.replace('%', ' ')
              this.email = val.e_mail
              this.dn = val.dname
              this.scanId = val.scan
              this.IdScanId = val.scanid
              this.IdData = val.iddata ? val.iddata.replace(/%/g, ', ') : ''
              this.role = val.type
              this.oid = val.certtype
            }
          })
        }
      },
      immediate: true
    },
    scanId: {
      handler: function (val) {
        if (val) {
          this.$http.post('/certificate/get-scan-name', { scan: val }).then(response => {
            if (response.data) {
              // console.log('scan: ' + response.data)
              this.scanName = response.data
            }
          })
        }
      },
      immediate: true
    },
    IdScanId: {
      handler: function (val) {
        if (val) {
          this.$http.post('/certificate/get-scan-name-id', { scan: val }).then(response => {
            if (response.data) {
              // console.log('scanId: ' + response.data)
              this.scanNameId = response.data
            }
          })
        }
      },
      immediate: true
    }
  },
  methods: {
    SendRevoke () {
      if (this.checkboxDocs) {
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.saveRevoke()
          }
        })
      } else {
        this.saveRevoke()
      }
    },
    getDoc () {
      this.$http.post('/certificate/get-scan', { scan: this.scanId }, { responseType: 'blob' }).then(response => {
        if (response.data) {
          const blob = new Blob([response.data], { type: 'application/octet-stream' })
          fileSaver.saveAs(blob, this.scanName)
        }
      })
    },
    getDocId () {
      this.$http.post('/certificate/get-scan-id', { scan: this.IdScanId }, { responseType: 'blob' }).then(response => {
        if (response.data) {
          const blob = new Blob([response.data], { type: 'application/octet-stream' })
          fileSaver.saveAs(blob, this.scanNameId)
        }
      })
    },
    saveRevoke () {
      this.$store.commit('updateRevokeData', this.paramsForSubmit);
      this.$router.push('/revokeResult');

      // this.$http.post('/certificate/save-revoke', this.paramsForSubmit).then(response => {
      //   if (response.data) {
      //     // console.log(response.data)
      //     this.$store.commit('updateResult', response.data)
      //     this.$router.push('/revokeResult')
      //   }
      // })
    }
  }
}
</script>

<style scoped>
  .has-user-border {
    border: 1px solid #339968;
  }
  .has-error {
    border-color: red;
  }
  .alert-error {
    color: red;
  }
</style>
