<template>
    <div id="app">
        <NavBar />
        <div id="content">
            <transition name="fade">
                <router-view></router-view>
            </transition>
        </div>
        <Footer />
    </div>
</template>

<script>
import NavBar from '@/components/NavBar'
import Footer from '@/components/Footer'
export default {
    name: 'App',
    components: { NavBar, Footer }
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
    transition-property: opacity;
    transition-duration: .10s;
}

.fade-enter-active {
    transition-delay: .10s;
}

.fade-enter,
.fade-leave-active {
    opacity: 0
}

#content {
    min-height: 80vh;
}

.navbar {
    min-height: 50px !important;
}

.modal-card {
    margin: 0 !important
}

.media {
    border: none !important;
}
</style>
