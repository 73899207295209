export default {
  methods: {
    startSocket() {
      this.cryptoSocket.connectTumSocket().then(() => {
        // localhost
        // this.cryptoSocket
        //   .setAPIKey(
        //     "ows5FWl29VCw+jrWivGJ69CIBW2BjFH7psYVj6X7pz+OIz+CXGOlRrdP0Rw+Z1GInINL+zWmcMn457CLz2TChRCidG+o7BYiJ1mwz7zDyWUe59gyH/vMBWgkzkUP4XTk4NBqBQgD7xTpriaSlQk36QjVrHApMhNl1OsslTzW4QwGwIIyY7O7eavnY6gk+714aq2R3d0VKQAuyoahL9dptnXL/X1mJ922mdZFbebI6SS00in1ofnrN4E1qg/i4vsP1U/kbCm+qIT6721g3TY3DaZIYOUN9Nx3bRqIalOaL1gYff65K6TEwuXODfDtGH76JO9dIwDXHDIacKUT13Blrw=="
        //   )
        //
        // 172.16.172.201
        // this.cryptoSocket
        //   .setAPIKey(
        //     "AayqFzh8tj7AFsDkP/1ehMc/BD3Az5viZilZJ9LanOY0UsoznksM2Klv6IWE1KaHu+FF2r3tH7gtWBwSpMIAJwTzLgWsqW2TK+h1ryiCQ/esKXTBev7Pl5j733cscaYancFaP0Kbj5bCSVOQFvBDi3MCEGs3OtvW5is4sXQtodmg6NWUZecZ71AmNQJTeOAzhlxkGR7jXO8nw4Lqp7JtS/3italLHmRhJ/yvaTlPg1qgAZvTBnvk4feHHd+lFoWmRTm01gRmSFSFxysCc+yYM793/govSGJv8selPeM1a1VgwRLNg278Rl8MjKJ1jupv7dGgjACawrbGBq+B8rjsmA=="
        //   )

          // ca.gamma.kz
          this.cryptoSocket.setAPIKey('lIpLQq8yVeLQnN6RNV9owtJODBd1VTzyJJpZlvCGWs5WwqrRqtKJl1WE9B7Ls/M1Kh2iqDRc3xBYd1Npf7DMPUgYVwYh8tZifLuWLpaul+FT1MyrFux74ymuq7+EBo+fg8zZe/Vd8jtLCeLwgEk5qKU6HSqQNrSJzXCQ7omdd02hX/p36SG75IICosmSJzJt+Nzm8fU6T8+UYqPPmqJGRuEBJT7vO6eroAnBkSmRmXrzuQdQHR2r7CxWAa+5BWkCjTAL5matKMZX6GN/pLGjFOUH2vhKz5BwiWlwNohVdzEd3du07o+Ob00Ed4u98xnX1FP1chJ7Cdyge0WOXpzg0g==')

          // this.cryptoSocket.setAPIKey('lIpLQq8yVeLQnN6RNV9owtJODBd1VTzyJJpZlvCGWs5WwqrRqtKJl1WE9B7Ls/M1Kh2iqDRc3xBYd1Npf7DMPUgYVwYh8tZifLuWLpaul+FT1MyrFux74ymuq7+EBo+fg8zZe/Vd8jtLCeLwgEk5qKU6HSqQNrSJzXCQ7omdd02hX/p36SG75IICosmSJzJt+Nzm8fU6T8+UYqPPmqJGRuEBJT7vO6eroAnBkSmRmXrzuQdQHR2r7CxWAa+5BWkCjTAL5matKMZX6GN/pLGjFOUH2vhKz5BwiWlwNohVdzEd3du07o+Ob00Ed4u98xnX1FP1chJ7Cdyge0WOXpzg0g==')
          // this.cryptoSocket.setAPIKey('lIpLQq8yVeLQnN6RNV9owtJODBd1VTzyJJpZlvCGWs5WwqrRqtKJl1WE9B7Ls/M1Kh2iqDRc3xBYd1Npf7DMPUgYVwYh8tZifLuWLpaul+FT1MyrFux74ymuq7+EBo+fg8zZe/Vd8jtLCeLwgEk5qKU6HSqQNrSJzXCQ7omdd02hX/p36SG75IICosmSJzJt+Nzm8fU6T8+UYqPPmqJGRuEBJT7vO6eroAnBkSmRmXrzuQdQHR2r7CxWAa+5BWkCjTAL5matKMZX6GN/pLGjFOUH2vhKz5BwiWlwNohVdzEd3du07o+Ob00Ed4u98xnX1FP1chJ7Cdyge0WOXpzg0g==')
          // this.cryptoSocket.setAPIKey('lIpLQq8yVeLQnN6RNV9owtJODBd1VTzyJJpZlvCGWs5WwqrRqtKJl1WE9B7Ls/M1Kh2iqDRc3xBYd1Npf7DMPUgYVwYh8tZifLuWLpaul+FT1MyrFux74ymuq7+EBo+fg8zZe/Vd8jtLCeLwgEk5qKU6HSqQNrSJzXCQ7omdd02hX/p36SG75IICosmSJzJt+Nzm8fU6T8+UYqPPmqJGRuEBJT7vO6eroAnBkSmRmXrzuQdQHR2r7CxWAa+5BWkCjTAL5matKMZX6GN/pLGjFOUH2vhKz5BwiWlwNohVdzEd3du07o+Ob00Ed4u98xnX1FP1chJ7Cdyge0WOXpzg0g==')
          // this.cryptoSocket.setAPIKey('lIpLQq8yVeLQnN6RNV9owtJODBd1VTzyJJpZlvCGWs5WwqrRqtKJl1WE9B7Ls/M1Kh2iqDRc3xBYd1Npf7DMPUgYVwYh8tZifLuWLpaul+FT1MyrFux74ymuq7+EBo+fg8zZe/Vd8jtLCeLwgEk5qKU6HSqQNrSJzXCQ7omdd02hX/p36SG75IICosmSJzJt+Nzm8fU6T8+UYqPPmqJGRuEBJT7vO6eroAnBkSmRmXrzuQdQHR2r7CxWAa+5BWkCjTAL5matKMZX6GN/pLGjFOUH2vhKz5BwiWlwNohVdzEd3du07o+Ob00Ed4u98xnX1FP1chJ7Cdyge0WOXpzg0g==')
          // this.cryptoSocket.setAPIKey('lIpLQq8yVeLQnN6RNV9owtJODBd1VTzyJJpZlvCGWs5WwqrRqtKJl1WE9B7Ls/M1Kh2iqDRc3xBYd1Npf7DMPUgYVwYh8tZifLuWLpaul+FT1MyrFux74ymuq7+EBo+fg8zZe/Vd8jtLCeLwgEk5qKU6HSqQNrSJzXCQ7omdd02hX/p36SG75IICosmSJzJt+Nzm8fU6T8+UYqPPmqJGRuEBJT7vO6eroAnBkSmRmXrzuQdQHR2r7CxWAa+5BWkCjTAL5matKMZX6GN/pLGjFOUH2vhKz5BwiWlwNohVdzEd3du07o+Ob00Ed4u98xnX1FP1chJ7Cdyge0WOXpzg0g==')
          // this.cryptoSocket.setAPIKey('lIpLQq8yVeLQnN6RNV9owtJODBd1VTzyJJpZlvCGWs5WwqrRqtKJl1WE9B7Ls/M1Kh2iqDRc3xBYd1Npf7DMPUgYVwYh8tZifLuWLpaul+FT1MyrFux74ymuq7+EBo+fg8zZe/Vd8jtLCeLwgEk5qKU6HSqQNrSJzXCQ7omdd02hX/p36SG75IICosmSJzJt+Nzm8fU6T8+UYqPPmqJGRuEBJT7vO6eroAnBkSmRmXrzuQdQHR2r7CxWAa+5BWkCjTAL5matKMZX6GN/pLGjFOUH2vhKz5BwiWlwNohVdzEd3du07o+Ob00Ed4u98xnX1FP1chJ7Cdyge0WOXpzg0g==')
          // this.cryptoSocket.setAPIKey('lIpLQq8yVeLQnN6RNV9owtJODBd1VTzyJJpZlvCGWs5WwqrRqtKJl1WE9B7Ls/M1Kh2iqDRc3xBYd1Npf7DMPUgYVwYh8tZifLuWLpaul+FT1MyrFux74ymuq7+EBo+fg8zZe/Vd8jtLCeLwgEk5qKU6HSqQNrSJzXCQ7omdd02hX/p36SG75IICosmSJzJt+Nzm8fU6T8+UYqPPmqJGRuEBJT7vO6eroAnBkSmRmXrzuQdQHR2r7CxWAa+5BWkCjTAL5matKMZX6GN/pLGjFOUH2vhKz5BwiWlwNohVdzEd3du07o+Ob00Ed4u98xnX1FP1chJ7Cdyge0WOXpzg0g==')
          // this.cryptoSocket.setAPIKey('lIpLQq8yVeLQnN6RNV9owtJODBd1VTzyJJpZlvCGWs5WwqrRqtKJl1WE9B7Ls/M1Kh2iqDRc3xBYd1Npf7DMPUgYVwYh8tZifLuWLpaul+FT1MyrFux74ymuq7+EBo+fg8zZe/Vd8jtLCeLwgEk5qKU6HSqQNrSJzXCQ7omdd02hX/p36SG75IICosmSJzJt+Nzm8fU6T8+UYqPPmqJGRuEBJT7vO6eroAnBkSmRmXrzuQdQHR2r7CxWAa+5BWkCjTAL5matKMZX6GN/pLGjFOUH2vhKz5BwiWlwNohVdzEd3du07o+Ob00Ed4u98xnX1FP1chJ7Cdyge0WOXpzg0g==')
          .then(() => {
            this.$store.commit("updateSocketKey", true);
          })
          .catch((error) => this.$store.commit("updateSocketKey", false));
      });
    },
  },
};
